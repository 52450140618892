@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,500;1,600&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

:root {
  --p-color: #b6101d;
  --a-color: #e23344;
  --s-color: #2856c8;
  --t-color: #f6f7f8;
  --h-color: #ba0e1e;
  --g-color: #f8e6e8;
  --green-color: #188c3e;
  --h-family: "Work Sans", sans-serif;
  --p-family: "Poppins", sans-serif;
  --white-color: #fff;
  --black-color: #000;
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}

* {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--h-family);
  letter-spacing: 1px;
  margin-bottom: 0;
}

p {
  font-family: var(--p-family);
  margin-bottom: 0;
}

a {
  font-family: var(--p-family);
}

.linked {
  color: var(--white-color);
  text-decoration: none;
  padding: 3px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.profile {
  float: right;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 0 22px;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--a-color) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.bottom_header {
  box-shadow: 0 0 5px #666 !important;
  background-color: rgb(245 240 240);
}

.input-group-text {
  border-radius: 0 25px 25px 0;
}

.search_field .input-group .form-control {
  border-right: none;
}

.search_field .form-control {
  padding: 18px !important;
  border-radius: 25px 0 0 25px !important;
}

.input-group :placeholder-shown {
  font-family: var(--h-family);
}

.input-group-text {
  background-color: white;
}

@media only screen and (max-width: 820px) {
  .icons span {
    margin-right: 10 !important;
  }
}

.navbar-nav {
  flex-direction: row;
}

.bottom_header .navbar-nav {
  margin-left: 30px;
}

.category_navbar {
  width: 394px;
  padding-left: 20px;
}

@media only screen and (max-width: 480px) {
  .category_navbar {
    padding-left: 0px;
    width: 100%;
  }
}

@media only screen and (max-width: 993px) {
  .icons {
    padding: 20px !important;
  }
}

.icons {
  color: #fff;
}

.icons i {
  padding-right: 5px;
  font-size: 17px;
}

.icons span {
  font-size: 17px;
  font-family: var(--h-family);
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .icons {
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .payment_type {
    padding-right: 2px !important;
  }
}

.navbar {
  text-align: center;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler-icon {
  color: var(--green-color);
  font-size: 32px;
  height: 40px;
  width: fit-content;
  padding-left: 20px;
}

@media only screen and (max-width: 480px) {
  .navbar-toggler-icon {
    padding-left: 0px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.span-sign {
  border-radius: 0.25rem;
  margin-right: 10px;
  font-size: 16px !important;
}

.nav-item .icons .nav-link {
  border: none;
}

.nav-item .icons:hover .nav-link {
  border: none;
}

.nav-item .icons .nav-link .linked:hover {
  color: var(--p-color);
  transition: 0.5s;
}

.nav-item .icons .nav-link .linked:hover i {
  color: var(--p-color);
  transition: 0.5s;
}

.nav-link i {
  color: var(--green-color);
}

.navbar-nav1 {
  display: none;
}

.navbar-nav {
  gap: 12px;
  align-items: center;
}

.nav-link {
  padding: 0px !important;
}

@media only screen and (max-width: 993px) {
  .navbar-nav.right-nav-items .nav-item {
    margin-left: 0rem;
  }
  .navbar-nav .icons {
    padding: 0px !important;
  }
  .navbar-nav .icons span {
    padding: 0px !important;
    font-weight: 400;
  }
  .navbar-nav1 {
    display: inline;
    margin: auto;
  }
  .nav-item .nav-link {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .nav-item .nav-link a {
    padding: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar-nav {
    padding-left: 0px;
  }
}

.span-sign:hover {
}

.search_field .form-control {
  height: 40px;
}

.search_field i {
  font-size: 20px;
}

.nav-item {
  font-size: 15px;
}

.right-nav-items .nav-item {
  margin-left: 10px;
}

.nav-link:hover {
  color: var(--p-color);
}

.nav-link:focus {
  color: var(--p-color);
}

select {
  font-family: var(--h-family);
}

.select {
  padding: 4px 20px;
  font-family: var(--h-family);
  border: none;
  color: var(--green-color);
  font-size: 17px;
}

.nav-item a {
  color: var(--green-color);
}

.login {
  margin: 60px 0;
}

.login-image {
  height: 400px;
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .login-image {
    display: none;
  }
  .register-image {
    display: none;
  }
  .payment_type img {
    height: 22px !important;
  }
}

.login-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.login-title {
  font-family: var(--h-family);
}

.login-title h6 {
  font-size: 30px;
  color: var(--a-color);
  text-transform: capitalize;
  font-weight: 600;
}

.login-title p {
  font-size: 13px;
  margin-top: 10px;
}

.input-group {
  border-radius: 0.25rem;
}

.login-form .input-group {
  width: 100%;
}

.login-form .input-group input {
  border-right: none;
  border-radius: 0.25rem !important;
}

.login-form .form-check-label {
  font-size: 15px;
  text-transform: capitalize;
}

.login-form label {
  width: 100%;
  font-family: var(--h-family);
  font-weight: bold;
  opacity: 80%;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
  padding-left: -1px;
}

.login-form .input-group-text i {
  color: #ebebeb;
}

.underline-click {
  color: var(--a-color);
  text-decoration: underline;
}

.main-topic .btn {
  display: inline;
  padding: 6px !important;
}

.main-topic span {
  color: var(--green-color);
}

.button .btn {
  color: var(--white-color);
  text-transform: capitalize !important;
  font-size: 15px;
  width: 100%;
  background-color: var(--green-color);
  margin-top: 20px;
  padding: 6px 0;
  border-radius: 5px;
  border: none;
  text-align: center;
}

.button .btn:hover {
  color: var(--white-color);
  background-color: rgb(21, 99, 47);
  border: none;
}

.back-heading {
  margin-bottom: 10px;
  font-family: var(--p-family);
}

.back-heading p {
  font-size: 12px;
}

.login .back-heading {
  text-align: center;
  margin-left: 140px;
}

.go-to {
  color: var(--black-color);
  opacity: 60%;
}

.register-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 0 64px 64px 64px;
}

.register-image img {
  height: 275px;
  width: 100%;
  overflow: hidden;
}

.register-main {
  margin: 32px 0;
}

.reset-verification-modal {
  text-align: center;
  padding: 36px 0;
}

.tick p {
  background-color: var(--h-color);
  display: inline-block;
  color: var(--white-color);
  border-radius: 50%;
  padding: 6px 12px;
}

.reset-topic {
  margin: 16px 0;
}

.reset-topic p {
  margin: 16px 0;
}

.btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.social_icon {
  margin-top: 20px;
}

.social_icon i {
  padding-right: 20px;
  font-size: 20px;
  color: #000000;
}

.social_icon i:hover {
  color: var(--a-color);
  transition: 0.5s;
}

.map {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  height: 320px;
  width: 100%;
  text-align: center;
}

.map .mapimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 820px) {
  .mapimg {
    height: 160px !important;
    width: 160px !important;
  }
  .map {
    padding-left: 0 !important;
  }
}

.copyright_text h6 {
  font-size: 18px;
}

.payment {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.payment_type {
  padding-right: 20px;
  margin-bottom: 30px;
  object-fit: cover;
}

.payment_type img {
  height: 42px;
}

.category-main {
  margin: 36px;
  text-align: center;
}

.category-main p {
  font-size: 12px;
  font-weight: bold;
  padding-top: 8px;
}

.main-topic {
  text-align: center;
  color: var(--p-color);
  font-size: 26px;
  font-weight: bold;
}

.cart-main {
  padding: 10px;
  border-right: 1px solid #ebebeb;
  box-shadow:
    rgba(50, 50, 93, 0.15) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
}

.cart_icons .col-md-6 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 700px) {
  .feature-img,
  .feature-data {
    padding: 0 1rem !important;
  }
  .mobilever_cart {
    padding: 0 1rem !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 900px) {
  .feature-img p {
    left: 77% !important;
  }
}

.feature-data {
  text-align: center;
  margin-top: 15px;
}

.mobilever_cart {
  display: flex !important;
  margin: 10px 0;
}

.mobilever_cart p {
  padding: 4px 10px;
  background-color: var(--h-color);
  color: #fff;
  margin-right: 10px;
  border-radius: 0.25rem;
  display: none !important;
  text-transform: uppercase;
  font-size: 13px;
}

.mobilever_cart p i {
  padding: 0 3px;
}

@media only screen and (min-width: 280px) {
  .feature_img p {
    left: 71% !important;
  }
}

@media only screen and (max-width: 500px) {
  .feature-img {
    height: 150px !important;
    position: relative;
    overflow: hidden;
  }
}

.feature-img {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.feature-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cart_icons {
  margin-top: 12px;
}

.cart-hand {
  border-radius: 0.25rem;
  background-color: rgb(24 140 62);
  transition: 0.5s;
}

.cart-hand:hover {
  background-color: #15632f;
}

.cart-hand .btn {
  color: #fff;
}

.out-stock {
  border-radius: 0.25rem;
  background-color: rgb(140, 24, 24);
  transition: 0.5s;
}

.out-stock .btn {
  color: #fff;
}

.feature-img p {
  /* position: absolute; */
  cursor: pointer;
  /* top: 1%;
    left: 80%; */
  font-size: 12px;
  color: white;
  background-color: var(--p-color);
  padding: 4px 6px;
  /* border-radius: 50%; */
}

.cart-main:hover .feature-data a .cart-content-name {
  color: var(--p-color);
  transform: scaleX(1.1);
  transition: 0.6s;
}

.cart-main {
  transition: 0.5s;
}

.cart-main:hover {
  box-shadow: 0 0 5px #d9d2d2 !important;
  cursor: pointer;
}

.cart-content-price {
  font-size: 17px;
  font-weight: 800;
  color: var(--p-color);
}

.cart-content-icon {
  color: gold;
  font-size: 12px;
}

.feature-data a {
  text-decoration: none;
}

.feature-data a .cart-content-name {
  font-size: 17px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--black-color);
  height: 55px;
}

.choose-content {
  text-align: center;
  height: 100%;
  object-fit: cover;
  margin: 26px 0;
}

.choose-content:hover {
  rotate: 360%;
}

.choose-content img {
  height: 160px;
  overflow: hidden;
}

.choose-content p {
  font-weight: bold;
}

.main-secondfeatured {
  margin: 64px 0 36px;
}

.detail-main {
  margin: 86px 0;
}

.detail-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.detail-img img {
  width: 100%;
  object-fit: contain;
}

.detail-content {
  margin: 56px 0 56px 36px;
}

.detail-content-head h3 {
  color: var(--s-color);
  font-weight: 600;
}

.detail-content-head h4 {
  font-size: 18px;
  margin: 16px 0;
  font-weight: 600;
}

.detail-content-head h4 > del {
  opacity: 50%;
  margin-left: 16px;
}

.detail-detail {
  margin: 26px 0;
}

.detail-detail-content p {
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 550;
}

.subtotal-shipping {
  margin-top: 15px;
}
@media only screen and (max-width: 360px) {
  .detail-detail-content p {
    height: 44px !important;
  }
  .detail-count {
    text-align: center !important;
    margin-left: 43px !important;
  }
  .subtotal-mob {
    font-weight: bold;
  }
  .cart-btn {
    margin-left: 43px !important;
  }
}

.units {
  opacity: 70%;
}

.detail-count {
  background-color: var(--t-color);
  margin-left: 55px;
  width: 60%;
  padding: 4px 8px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.detail-count button {
  border: none;
  background-color: var(--t-color);
  color: var(--s-color);
  font-size: 18px;
  font-weight: bold;
  padding: 0 10px;
}

.detail-count input {
  background-color: var(--t-color);
  border: none;
  width: 25%;
  text-align: center;
}

.cart-btn:hover {
  cursor: pointer;
}

.cart-btn p {
  color: var(--h-color);
  display: inline-block;
  padding: 6px 22px;
  background-color: var(--g-color);
}

.cart-btn p > span {
  padding: 0 6px;
}

.cart-btn p:hover {
  background-color: var(--s-color);
  color: #ebebeb;
}

.detail-text {
  text-align: justify;
  margin-top: 30px;
}

.detail-text h4 {
  color: var(--s-color);
  font-weight: 600;
}

.detail-text p {
  margin: 12px 0;
}

.other-main h3 {
  color: var(--s-color);
  font-weight: bold;
  margin: 50px 0 16px;
}

/*     ADD TO CART CSS     */

.cart-main {
  margin: 25px 0 25px;
}

.cart-table {
  text-align: center;
}

.cart-form {
  margin: 16px 0;
}

.btn-shopping .btn {
  width: fit-content;
  padding: 6px 10px;
}

@media only screen and (max-width: 360px) {
  .remove {
    width: 0.25rem !important;
  }
  .remove i {
    font-size: 12px;
    padding: 3px 2px !important;
    border-radius: 50% !important;
  }
  .product-img {
    width: 0.25rem !important;
    font-size: 12px;
  }
  .product-img img {
    height: 35px !important;
  }
  .product-name {
    font-size: 12px;
  }
  .price {
    font-size: 12px;
  }
  .quantity {
    font-size: 12px;
    text-align: center !important;
  }
  .unitprice {
    font-size: 12px;
  }
}

.remove {
  width: 5rem;
}

.remove i {
  background-color: var(--p-color);
  color: var(--g-color);
  border-radius: 50%;
  padding: 3px 4px;
  opacity: 60%;
}

.product-img {
  width: 13rem;
}

.product-img img {
  height: 80px;
  object-fit: contain;
  overflow: hidden;
}

.product-name {
  width: 23rem;
}

.price {
  width: 11rem;
}

.quantity {
  width: 17rem;
}

.subtotal {
  font-family: var(--p-family);
}

.total-cart {
  font-size: 20px;
  font-weight: bold;
}

.checkout button {
  border: none;
  width: 100%;
  margin: 18px 0;
  color: var(--t-color);
  border-radius: 10px;
  background-color: var(--s-color);
  padding: 8px;
}

.checkout button:hover {
  background-color: var(--black-color);
}

/*    terms and conditions    */

.terms-main {
  margin: 64px 0;
  text-align: justify;
}

.terms-body h3 {
  margin-bottom: 30px;
}

.terms-body p {
  margin-bottom: 20px;
}

/*   categories content   */

.categories-content-main {
  margin: 64px 0;
}

.categories-content-bar {
  background-color: var(--t-color);
  padding: 12px 0;
  font-weight: 500;
  width: 100%;
  box-shadow: 3px 2px 12px 3px rgb(0 0 0 / 10%);
}

.left-bar {
  padding: 10px 0px 10px 10px;
}

.no-items {
  text-align: center;
  margin-top: 40px;
}

.pagination-main {
  justify-content: center !important;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .profile-changes-btn button {
    font-size: 10px;
    width: 80% !important;
  }
  .edit-profile-main {
    text-align: center !important;
  }
  .edit-profile-main h3 {
    color: var(--black-color) !important;
  }
  .link-profile p {
    color: var(--g-color) !important;
  }
  .link-profile p:hover {
    background-color: var(--g-color) !important;
    border-radius: 10px;
    padding: 4px 5px;
    color: var(--h-color) !important;
  }
  .left-bar ul > li {
    font-size: 10px !important;
  }
  .right-bar {
    font-size: 10px !important;
  }
  .right-bar form {
    width: 100px !important;
  }
  .categories-content-bar {
    padding: 6px 0 !important;
  }
}

.left-bar ul > li {
  display: inline-block;
  list-style-type: none;
  margin-left: 12px;
  font-size: 12px;
}

.right-bar {
  font-size: 12px;
  float: right;
}

.right-bar form {
  width: 181px;
}

.categories-content-option {
  padding: 12px;
}

.categories-content-select {
  border: none;
  padding: 6px 6px;
  margin-left: 6px;
  width: 60%;
}

.form-control-price {
  margin-left: 6px;
  width: 32%;
}

@media only screen and (max-width: 992px) {
  .form-control-price {
    margin-bottom: 20px !important;
  }
  .form-control-sort {
    margin-bottom: 20px !important;
  }
}

.form-control-price :focus {
  outline-style: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

@media only screen and (max-width: 700px) {
  .manage-profile-main h4 {
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0px;
    word-spacing: 0.1px !important;
  }
  .manage-profile-main ul {
    padding-left: 15px !important;
    line-height: 8px;
  }
  .manage-profile-main ul > li {
    font-size: 10px;
  }
  .manage-profile-main ul > li > p {
    font-size: 8px !important;
  }
  .main-left-bar {
    background-color: var(--h-color);
    color: var(--g-color);
    padding: 8px 16px;
    border-radius: 10px;
  }
}

.categories-content-body {
  margin-top: 30px;
}

.code-verification-main {
  margin: 64px 0;
  text-align: center;
}

.code-verify {
  margin: 36px 0;
}

.code-input {
  margin: 26px 0;
  padding: 26px 0;
  background-color: var(--a-color);
  border-radius: 50%;
}

@media only screen and (max-width: 360px) {
  .code-input {
    margin: 5px 0 !important;
    padding: 9px 6px !important;
  }
  .code-input input {
    width: 20px !important;
  }
}

.code-input input {
  width: 40%;
  border: none;
  padding: 6px;
}

/*     Profile Css     */

.profile-main {
  margin: 64px 0;
  font-family: var(--h-family);
}

.manage-profile-main h4 {
  font-family: var(--h-family);
  font-size: 22px;
  font-weight: bold;
}

.manage-profile-main ul > li {
  list-style-type: none;
  margin: 8px 0;
}

.manage-profile-main ul > li:hover p {
  background-color: var(--a-color);
  color: var(--white-color) !important;
  display: inline-block;
  border-radius: 0.25rem;
}

.manage-profile-main ul > li > p {
  padding: 4px 0;
}

.edit-profile-main h4 {
  margin-bottom: 16px;
}

.edit-profile-content {
  background-color: var(--p-color);
  color: var(--g-color);
  padding: 64px;
}

.change-btn {
  color: #fff;
  text-decoration: none;
}

.change-btn:hover {
  background-color: var(--g-color);
  color: black;
  box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  /* .cartbutton .btn {
        font-size: 12px !important;
        line-height: 1 !important;
    } */
  .categories-content-select {
    width: auto !important;
  }
}

@media only screen and (max-width: 992px) {
  .cartbutton .btn {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .edit-profile-wishlist {
    padding: 26px 0;
  }
  .edit-profile-content {
    padding: 36px !important;
  }
}

.cart-btn .wishlist {
  background-color: var(--green-color);
  color: #fff;
}

.cart-btn .wishlist i {
  margin-right: 3px;
}

.edit-profile-wishlist {
  background-color: var(--p-color);
  color: var(--g-color);
}

.name-field {
  margin-bottom: 26px;
}

.name-field input {
  border: none;
  padding: 4px 8px;
  width: 100%;
}

.name-field select {
  border: none;
  padding: 4px 8px;
  width: 100%;
}
.add-dob {
  text-align: left !important;
}

.profile-changes-btn {
  text-align: center;
  margin: 26px 0;
}

.profile-changes-btn button {
  border: none;
  width: 50%;
  padding: 4px 0;
  border-radius: 0.25rem;
}

.profile-changes-btn button:hover {
  background-color: var(--black-color);
  color: var(--g-color);
}

.link-profile {
  text-decoration: none;
  color: var(--black-color);
}

.link-profile:hover {
  color: var(--a-color) !important;
}

.edit-profile-main h3 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 12px;
  color: var(--h-color);
}

.modal-title {
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
}

.modal-body label {
  color: var(--black-color) !important;
  padding: 10px 0 5px 0;
  font-size: 15px;
  font-family: var(--h-family);
  font-weight: 600;
}

.modal-body input {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #ebebeb;
}

/* contact page */

.contact_heading {
  margin: 25px 0;
  text-align: center;
  font-family: var(--h-family);
}

.contact_heading h3 {
  font-size: 25px;
  letter-spacing: 1px;
}

.contact_map {
  height: 400px;
  width: 100%;
  overflow: hidden;
}

.contact_map .whole_map {
  height: 100%;
  width: 100%;
}

.contact_info {
  margin-top: 30px;
  margin-bottom: 30px;
}

.basic_info {
  border: 1px solid #ebebeb;
  padding: 30px;
  border-radius: 0.25rem;
  font-family: var(--h-family);
}

.info {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

.nums h5 {
  font-size: 18px;
  padding-top: 20px;
}

.info i {
  padding-right: 30px;
  font-size: 20px;
  padding-top: 20px;
}

.social_icon_contact {
  text-align: center;
  padding: 20px;
}

.social_icon_contact i {
  padding-right: 25px;
  font-size: 25px;
  transition: 0.5s;
}

.social_icon_contact i:hover {
  color: var(--p-color);
}

.social_icon_contact a {
  text-decoration: none;
  color: #000;
}

.basic_info .btn {
  background-color: var(--green-color);
  color: var(--white-color);
}

li {
  list-style: none;
}

.info_heading {
  text-align: center;
}

.info_heading h5 {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}

/* about-page */

.about_image {
  height: 400px;
  width: 100%;
}

.about_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about_desc {
  margin: 20px 0;
  font-family: var(--p-family);
}

.about_desc p {
  font-size: 16px;
}

/* -- Payment -- */

.payment-method {
  padding: 20px 0px 10px 0px;
  font-size: 22px;
  font-weight: 400;
  color: var(--white-color);
}

.payment-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  margin-bottom: 4px;
  color: #404040;
}

.payment-sub-title {
  font-size: 12px;
  line-height: 14px;
  color: #757575;
}

/* .esewa {
  padding-bottom: 10px;
  height: 82px;
  width: 62px;
}

.esewa img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} */

.payment-section {
  background-color: aliceblue;
  padding: 10px 10px;
  margin-top: 20px;
}

.payment-section :hover {
  cursor: pointer;
}

.payment-active {
  border: 5px solid #2856c8;
}

.payment-section img {
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  height: 40px;
  /* border-radius: 6px; */
  padding: 10px;
  border: none;
}

.input-field:focus {
  outline: none;
}

.right {
  float: right !important;
}

.left {
  float: left;
}

.order-summary {
  margin: 14px 35px;
  padding: 20px;
  background-color: var(--p-color);
  color: white;
}

.form-control-second {
  margin: 0;
}

.total-table {
  width: 100%;
}

.summary-btn {
  margin-top: 10px !important;
}

/* @media screen and (max-width: 800px) {
    .form-control {
         margin-top: 18px; 
    }
} */

.pop-up-buttom {
  text-align: center;
  margin: 0px 36px;
}

.pop-up-buttom button {
  width: 100%;
}

.checkout-header {
  padding-top: 30px;
}

.checkout-section {
  background-color: var(--p-color);
  padding: 25px;
  margin: 30px 0px;
}

.checkout-submit {
  margin-top: 25px;
  padding: 5px 10ppx;
  font-size: 13px;
  border-radius: 0;
}

.checkout-section label {
  color: white;
}

.modal-footer {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer .btn {
  width: fit-content;
  padding: 5px 10px;
}

.error-message {
  color: red !important;
  font-size: 10px;
  font-weight: 600;
}

.filter-btn {
  text-align: center;
  margin-top: 26px;
}

.filter-btn button:hover {
  color: #fff;
  background-color: #a30f1b;
}

.filter-btn button {
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-radius: 4px;
  background-color: var(--p-color);
  color: var(--t-color);
  padding: 4px 24px;
  border: none;
}

.order-table {
  overflow-x: hidden;
  /* display: none; */
}

.order-list-table td {
  text-align: right;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #fff;
  will-change: opacity, transform;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
  color: black;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe-form button {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

.stripe-form button:active {
  background-color: #d782d9;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* Your existing CSS */
.cart-main {
  /* Add any existing styles for your cart main container */
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.cart-table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.cart-table th,
.cart-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.cart-table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.cart-table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.detail-count.collect {
  display: flex;
  align-items: center;
}

.detail-count.collect button {
  padding: 0.5rem;
  background: none;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.detail-count.collect input {
  width: 50px;
  text-align: center;
  border: 1px solid #ced4da;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .cart-table thead {
    display: none;
  }

  .cart-table,
  .cart-table tbody,
  .cart-table tr,
  .cart-table td {
    display: block;
    width: 100%;
  }

  .cart-table tr {
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    padding: 0.75rem;
  }

  .cart-table td {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  .cart-table td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 50%;
  }

  .cart-table .product-img img {
    width: 50px;
    height: auto;
    margin-right: 0.5rem;
  }

  .subtotal-main .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .subtotal-main .row .col-md-4 {
    width: 100%;
    padding: 0.5rem 0;
  }

  .subtotal-main .subtotal {
    width: 100%;
  }
}

/* Existing CSS remains the same */

/* Responsive Styles */
@media (max-width: 768px) {
  .subtotal-main {
    margin-top: 1rem;
  }

  .subtotal-main .subtotal {
    padding: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
  }

  .subtotal-main .subtotal .row {
    margin-bottom: 0.5rem;
  }

  .subtotal-main .subtotal .col-md-6 {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .subtotal-main .subtotal .col-md-4 {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  .subtotal-main .subtotal hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-color: #ced4da;
  }

  .checkout {
    text-align: center;
    margin-top: 1rem;
  }
}

.select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  background-color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .select {
    font-size: 0.875rem;
  }
}

.cart-content-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: normal;
  min-height: 3em;
  line-height: 1.5em;
}

/* src/Pages/NotFound.css */

.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  text-align: center;
  background-color: #f8f9fa;
}

.notfound-title {
  font-size: 10rem;
  margin: 0;
  color: #343a40;
}

.notfound-message {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #6c757d;
}

.notfound-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: var(--h-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.notfound-link:hover {
  background-color: var(--h-color);
}

.hero .slick-prev {
  left: 20px;
  z-index: 10;
}

.hero .slick-next {
  right: 20px;
}

.hero .slick-next::before {
  opacity: 1;
  font-size: 24px;
  color: var(--white-color) !important;
}

.hero .slick-prev::before {
  opacity: 1;
  font-size: 24px;
  color: var(--white-color) !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: var(--green-color) !important;
  box-shadow: none !important;
}

.form-control:focus-visible {
  outline: 0;
}
